.slogan-wrapper {
  margin-bottom: 20px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.5);
  h1 {
    margin: 0 auto;
    padding: 0;
    font-size: 36pt;
    font-family: "Helvetica Neue", sans-serif;
  }
}

.circle-icon {
  background: #ffc0c0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  padding: 30px;
}

table.deliverables tbody tr td {
  padding-bottom: 50px;
  margin-bottom: 100px;
}

.jumbotron.jumbotron-fluid {
  text-align: center;
  background-color: #f3f3f3;
  margin: 0 auto;

  h1 {
    font-size: 30px;
  }

  table {
    max-width: 800px;
    margin: 0 auto;
    tr {
      td {
        border: 0;
        width: 300px;
      }
      td.text-large {
        font-size: 20px;
      }
      td.text-small {
        font-size: 10pt;
        text-align: justify;
      }
    }
  }
}

